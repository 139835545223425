import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'intello-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

    @Input() templateRef: TemplateRef<any>;

    private _isLoading: boolean;

    @Input()
    get isLoading() {
        return this._isLoading;
    }
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    private _cssClass: string;

    @Input()
    get cssClass() {
        return this._cssClass;
    }
    set cssClass(value: string) {
        this._cssClass = value;
    }

    constructor() { }
}
