import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'projects/cms/src/app/core/authentication/authentication.service';
import { environment } from 'projects/cms/src/environments/environment';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../base/components/base-component';
import { Guid } from '../../functions/guid';
import { __ } from '../../functions/object.functions';

@Component({
  selector: 'intello-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent extends BaseComponent implements OnInit, OnDestroy {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isLoading: boolean = true;

  allowDynamicSetting = false;

  objectUrl: string;

  // -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD/CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @ViewChild('image', { static: true }) image: ElementRef;


  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() loadingContainerClass: string = '';

  @Input() customClass: string = 'image';

  @Input() loadingClass: string = '';

  @Input() height: string = 'auto';

  @Input() defaultImage: string = '';

  @Input() width: string = 'auto';

  @Input() minHeight: string = 'initial';

  private _src: string;
  @Input()
  get src() {
    return this._src;
  }
  set src(source: string) {
    const hasChanged = this._src !== source;

    this._src = source;
    if (this.allowDynamicSetting && hasChanged === true) {
      this.loadImage();
    }
  }

  private _type: string;
  @Input()
  get type(): string {
    return this._type;
  }
  set type(type: string) {
    this._type = type;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.loadImage();
    this.allowDynamicSetting = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    const urlCreator = window.URL || window.webkitURL;
    urlCreator.revokeObjectURL(this.objectUrl);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private loadImage() {
    let alreadyLoaded = false;

    let alreadyErroneous = false;

    if (!__.IsNullOrUndefinedOrEmpty(this.src) && this.src.length === Guid.Empty.length) {
      let headers;

      if (!__.IsNullOrUndefinedOrEmpty(this.authenticationService.credentials)) {
        headers = new HttpHeaders({
          'Authorization': `Bearer ${this.authenticationService.credentials.access_token}`,
          'Cache-Control': `private, max-age=10000000`
        });
      }

      const options: {
        headers?: HttpHeaders;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType: any,
        withCredentials?: boolean;
      } = {
        responseType: 'blob',
        headers
      };

      return this.httpClient
        .disableApiPrefix()
        .disableAccessToken()
        .skipErrorHandler()
        .get(
          `${environment.cmsServerUrl}v1/files/${this.src}`,
          options
        )
        .pipe(
          map((file: ArrayBuffer) => {
            return new Blob([file]);
          })
        )
        .subscribe({
          next: (image: Blob) => {
            const urlCreator = window.URL || window.webkitURL;
            this.objectUrl = urlCreator.createObjectURL(image);
            this.image.nativeElement.src = this.objectUrl;
            setTimeout(() => {
              this.isLoading = false;
              this.isLoaded.emit(true);
              this.cdr.detectChanges();
            }, 1)
          },
          error: (error: any) => {
            setTimeout(() => {
              this.isLoading = false;
              this.isLoaded.emit(true);
              this.cdr.detectChanges();
            }, 1)
          }
        })
    }

    this.image.nativeElement.onload = () => {
      this.isLoading = false;
      this.isLoaded.emit(true);
      this.cdr.detectChanges();
    };
    this.image.nativeElement.onerror = () => {
      if (alreadyLoaded === true) {
        if (alreadyErroneous === false) {
          this.image.nativeElement.src = 'assets/150x150.png';
          alreadyErroneous = true;
        }
      } else {
        this.isLoading = false;
        alreadyLoaded = true;
        this.image.nativeElement.src = this.defaultImage;
      }
      this.cdr.detectChanges();
    };

    // Image by source
    if (__.IsNullOrUndefinedOrEmpty(this.src)) {
      this.image.nativeElement.src = this.defaultImage;
    } else {
      if (this.src.length !== Guid.Empty.length) {
        this.image.nativeElement.src = this.src;
      }
    }
  }

}
